import React from 'react'
import WinkingBot from '../kawaii/WinkingBot'
import styles from './Home.module.css'
//import { functions } from '../firebase/Firebase'
//import StartInterview from '../interviews/StartInterview'

const Home = () => {
  //   const callFunction = () =>
  //     functions
  //       .httpsCallable('helloWorld')()
  //       .then(result => {
  //         console.log(result)
  //       })
  return (
    <>
      <section className={styles.home}>
        <WinkingBot />
        <div className={styles.header}>
          <h1>Little Interview</h1>
        </div>
      </section>
      {/* <section>
        <StartInterview />
      </section> */}
    </>
  )
}

export default Home
