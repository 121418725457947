import React, { Component, RefObject } from 'react'
import { SpeechBubble, Props as KawaiiProps } from 'react-kawaii'
import { styler, tween, action, merge } from 'popmotion'

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
type Props = Omit<KawaiiProps, 'mood'>

class WinkingBot extends Component<Props> {
  botRef: RefObject<HTMLDivElement>

  constructor(props: Props) {
    super(props)
    this.botRef = React.createRef()
  }

  componentDidMount() {
    if (!this.botRef.current) return
    const eyeLeft = styler(this.botRef.current.querySelector(
      '#kawaii-face__eyes__circle circle:first-child'
    ) as Element)
    const eyeRight = styler(this.botRef.current.querySelector(
      '#kawaii-face__eyes__circle circle:last-child'
    ) as Element)
    const showEye = tween({
      from: { scaleY: 0 },
      to: { scaleY: 1 },
      duration: 200
    })

    const blinkEye = tween({
      from: { scaleY: 1 },
      to: { scaleY: 0.3 },
      duration: 50,
      flip: 1
    })

    const eyeLeftAction = action(({ complete }) => {
      showEye.start({
        update: eyeLeft.set,
        complete: () => {
          complete()
          setInterval(() => blinkEye.start({ update: eyeLeft.set }), 8000)
        }
      })
    })

    const eyeRightAction = action(({ complete }) => {
      showEye.start({
        update: eyeRight.set,
        complete: () => {
          complete()
          setInterval(() => blinkEye.start({ update: eyeRight.set }), 8000)
        }
      })
    })

    merge(eyeRightAction, eyeLeftAction).start()
  }

  render() {
    return (
      <div ref={this.botRef}>
        <SpeechBubble {...this.props} mood="happy" />
      </div>
    )
  }
}
export default WinkingBot
