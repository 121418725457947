import React from 'react'
import Title from '../common/Title'

const Login = () => (
  <>
    <Title prepend="Login" />
    <section className="hero">
      <div className="hero-body">
        <div className="container">
          <h1 className="title">Login</h1>
        </div>
      </div>
    </section>
  </>
)

export default Login
