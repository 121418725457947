import React from 'react'
import { Helmet } from 'react-helmet'

interface Props {
  prepend?: string
}

const Title = ({ prepend }: Props) => (
  <Helmet>
    <title>
      {!!prepend ? `${prepend} | Little Interview` : 'Little Interview'}
    </title>
  </Helmet>
)

export default Title
