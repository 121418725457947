import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Routes from './routes/routes'
import Home from './home/Home'
import Login from './acount/Login'

const App = () => (
  <Router>
    <>
      <Route exact path={Routes.Home} component={Home} />
      <Route path={Routes.Login} component={Login} />
    </>
  </Router>
)

export default App
